import api, { baseURL } from "../../../../utils/api"

export const getNewClients = (page, limit) =>{
	return api.get(`${baseURL}/user/newclients?page=${page}&size=${limit}`);
}

export const approveClient = (client, isSwitchAccount = false) =>{
	return api.post(`${baseURL}/auth/approveuser`, {id: client, isSwitchAccount });
}

export const declineClient = (client) =>{
	return api.post(`${baseURL}/auth/declineuser`, {id: client});
}

export const deleteClient = (clientId) =>{
	return api.delete(`${baseURL}/user/deleteuser?id=${clientId}`);
}