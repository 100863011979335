import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom';

function JobCategory() {
  const context = useOutletContext();
  return (
    <Outlet context={context}/>                                                     
  )
}

export default JobCategory