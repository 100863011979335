import { useLocation, useSearchParams } from "react-router-dom";


const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const getAllQueryParamString = location.search;
  
  const getParam = (key) => {
    return searchParams.get(key);
  };

  const setParam = (key, value) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value);
    setSearchParams(newParams);
  };

  const setMultiParam = (paramsObj) => {
    const newParams = new URLSearchParams(searchParams);
    
    // Loop through the object to set each query param
    for (const [key, value] of Object.entries(paramsObj)) {
      if (value !== undefined && value !== null) {
        newParams.set(key, value);
      }
    }

    setSearchParams(newParams);
  };

  const removeParam = (key) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete(key);
    setSearchParams(newParams);
  };

  const clearParams = () => {
    setSearchParams({});
  };

  return {getParam, getAllQueryParamString, setParam, setMultiParam, removeParam, clearParams}
}

export default useQueryParams;
