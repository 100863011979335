import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../../components/Navbar";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  getEmailTemplateDesignByID,
  updateEmailTemplateDesign,
} from "./Helper";

const EmailTemplateDesign = () => {
  const context = useOutletContext();
  const location = useLocation();
  const emailEditorRef = useRef(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: location.state?.name,
      subject: location.state?.subject,
      description: location.state?.description,
    },
  });

  const onReady = () => {
    getEmailTemplateDesignByID("115a8bc3-4242-4956-a198-399624f9bb67")
      .then((res) => {
        console.log(res.data);
        if (res.data?.json_content !== null) {
          emailEditorRef.current.editor.loadDesign(
            JSON.parse(res.data?.emailTemplateDesign?.json_content)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    context(["Email Templates", "Template Design"]);
  }, []);
  const submit = (formData) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log(data);
      updateEmailTemplateDesign({
        id: "115a8bc3-4242-4956-a198-399624f9bb67",
        json_content: JSON.stringify(design),
        content: html,
      })
        .then((res) => {
          console.log(res.data);
          navigate("/EmailTemplates/Templates");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Template Design
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <EmailEditor
            style={{ height: "100vh" }}
            ref={emailEditorRef}
            onReady={onReady}
          />
          <div className="text-right pt-10">
            <Button
              type="submit"
              variant="contained"
              style={{ color: "white" }}
            >
              Save Template Design
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailTemplateDesign;
