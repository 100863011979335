import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

const UserManager = () => {
  const context = useOutletContext();
  return (
    <Outlet context={context}/>
  )
}

export default UserManager