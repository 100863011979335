import React, { useEffect, useState, useRef, useContext } from "react";
import Navbar from "../../../components/Navbar";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { createEmailTemplate, updateEmailTemplate } from "./Helper";

const EmailTemplateUpdate = () => {
  const context = useOutletContext();
  const location = useLocation();
  const id = location.state?.id == null ? null : location?.state?.id;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: location.state?.name,
      subject: location.state?.subject,
      description: location.state?.description,
      content: location.state?.content,
    },
  });

  useEffect(() => {
    context([
      "Email Templates",
      `${id == null ? "Create" : "Update"} Email Template`,
    ]);
  }, []);

  const submit = (formData) => {
    if (id == null) {
      createEmailTemplate({
        name: formData?.name,
        description: formData?.description,
        content: formData?.content,
        subject: formData.subject,
      })
        .then((data) => {
          console.log(data.data);
          navigate("/EmailTemplates/Templates");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateEmailTemplate({
        id: id,
        name: formData?.name,
        description: formData?.description,
        content: formData?.content,
        subject: formData.subject,
      })
        .then((data) => {
          console.log(data.data);
          navigate("/EmailTemplates/Templates");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          {id == null ? "Create" : "Update"} Email Template
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <div className="w-80">
            <TextField
              id="outlined-basic"
              {...register("name", { required: true })}
              label="Name *"
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errors.name && (
              <p className="text-red-500 text-xs">Template name is required</p>
            )}
            <br />
            <TextField
              id="outlined-basic"
              {...register("subject", { required: true })}
              label="Subject *"
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errors.subject && (
              <p className="text-red-500 text-xs">
                Template Subject is required
              </p>
            )}
            <br />
            <TextField
              id="outlined-basic"
              {...register("description", { required: true })}
              multiline
              minRows={3}
              label="Description *"
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errors.name && (
              <p className="text-red-500 text-xs">
                Template description is required
              </p>
            )}
          </div>
          <br />
          <TextField
            id="outlined-basic"
            {...register("content", { required: true })}
            multiline
            minRows={10}
            label="Content *"
            margin="dense"
            variant="outlined"
            size="small"
            fullWidth
          />
          <div className="text-right pt-10">
            <Button
              type="submit"
              variant="contained"
              style={{ color: "white" }}
            >
              {id == null ? "Add" : "Update"} Email Template
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailTemplateUpdate;
