import Swal from "sweetalert2";
import MuiModal from "../../MuiModal";
import React, { useEffect } from "react";

function DeactivateUserModal({ open, setOpen, deactivateUser, title}) {

  const deleteUser = (e) => {
    e.preventDefault();
    typeof deactivateUser === 'function' && deactivateUser();
    setOpen(false);
  }

  return (
    <MuiModal open={open} onCancel={() => setOpen(false)} title={title}>
      <div className="flex flex-col">
        <span className="text-base">Do you want to delete this user ?</span>
        <div className="flex justify-center pt-4">
          <button className={`hover:bg-red-400 bg-red-500 text-white py-2 px-4 rounded-lg mr-4`} onClick={deleteUser}>Yes</button>
          <button className={`text-white hover:bg-green-400 py-2 px-4 rounded-lg bg-[#2ebaa3]`} onClick={ () => setOpen(false) }>No</button>
        </div>
      </div>
    </MuiModal>
  );
}

export default DeactivateUserModal;
