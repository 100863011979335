import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { addSkill, updateSkill } from "./Helper";

const JobMSkillUpdate = () => {
  const context = useOutletContext();
  const location = useLocation();
  const id = location.state?.id == null ? null : location?.state?.id;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: location.state?.name,
    },
  });

  useEffect(() => {
    context(["Job Manager", `${id == null ? "Create" : "Update"} Skill`]);
  }, []);

  const submit = (formData) => {
    if (id == null) {
      addSkill({ name: formData?.name })
        .then((data) => {
          console.log(data.data);
          navigate("/JobManager/Skill");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(id);
      updateSkill({ id: id, name: formData?.name })
        .then((data) => {
          console.log(data.data);
          navigate("/JobManager/Skill");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          {id == null ? "Create" : "Update"} Skill
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <div className="w-80 pt-2">
            <TextField
              id="outlined-basic"
              {...register("name", { required: true })}
              label="Skill *"
              margin="normal"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errors.name && (
              <p className="text-red-500 text-xs">Skill is required</p>
            )}
          </div>
          <div className="text-right pt-10">
            <Button
              type="submit"
              variant="contained"
              style={{ color: "white" }}
            >
              {id == null ? "Add" : "Update"} Skill
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobMSkillUpdate;
