import React from 'react';
const Header = () => {
	return (
		<div className="grid grid-cols-8">
			<form class="flex items-center h-14 col-span-4" style={{ borderColor: '#fff' }}>
				<label htmlFor="simple-search" class="sr-only">
					Search
				</label>
				<div class="relative w-full">
					<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
						<svg
							aria-hidden="true"
							class="w-5 h-5 text-gray-500 dark:text-gray-400"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
								clip-rule="evenodd"
							></path>
						</svg>
					</div>
					<input                                                                         
						style={{ borderColor: '#fff' }}
						type="text"
						id="simple-search"
						class="text-gray-900 text-sm block w-full pl-10 p-2.5 focus:outline-none"
						placeholder="Search"
						required
					/>
				</div>
			</form>
			{/* <div className="flex items-center flex-row-reverse h-14 col-span-4">
				<button className="text-base uppercase px-3 py-2 rounded-lg font-semibold bg-red-600 m-2" onClick={logout} style={{ color: 'white' }}>Logout</button>
				<img className="h-12 w-12 mx-2" src={Avtar} alt="User Avtar" />
				<h4 className="text-base uppercase px-3 font-semibold" style={{ color: '#2E2A75' }}>
				
				</h4>
			</div> */}
			{/* <div className="flex items-center h-14">
			</div> */}
		</div>
	);
};

export default Header;
