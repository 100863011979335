import api, { baseURL } from "../../../utils/api";

/** @desc GET */
export async function getJobCategoryList (page, limit) {
  return api.get(`${baseURL}/jobnproject/getjobcategory?page=${page}&&size=${limit}`);
}

/** @desc POST */
export async function addJobCategory (body) {
  return api.post(`${baseURL}/jobnproject/addjobcategory`, body);
}

/** @desc PUT */
export async function updateJobCategory (id, body) {
  return api.put(`${baseURL}/jobnproject/updatejobcategory?id=${id}`, body);
}

/** @desc DELETE */
export async function deleteJobCategory (id) {
  return api.delete(`${baseURL}/jobnproject/deletejobcategory?id=${id}`);
}
// http://localhost:4001/api/v1/jobnproject/addjobcategory  post   "category":""   From body  string
// http://localhost:4001/api/v1/jobnproject/getjobcategory  get     { page, size } = req.query
// http://localhost:4001/api/v1/jobnproject/updatejobcategory?id=bab0d848-109c-46d4-8743-09e3fe21e5b2 put   query m id
// http://localhost:4001/api/v1/jobnproject/deletejobcategory?id=73fb957b-3ae9-4a76-a6de-cdbd0cac400c   delete