import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

const FAQ = () => {
  const context = useOutletContext();
  return (
    <Outlet context={context}/>
  )
}

export default FAQ