import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { deleteBusinessType, getBusinessTypes } from "./Helper";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { TablePagination } from "@mui/material";

const BusinessTypeList = () => {
  const navigate = useNavigate();
  const context = useOutletContext();
  const [businessTypes, setBusinessType] = useState([]);
  const [businessTypesPage, setBusinessTypePage] = useState(0);
  const [businessTypeLimit, setBusinessTypeLimit] = useState(10);
  const [businessTypeTotal, setBusinessTypeTotal] = useState(0);
  const [update, setUpdate] = useState(false);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, businessTypeLimit);
    setBusinessTypePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setBusinessTypeLimit(parseInt(event.target.value, 10));
    setBusinessTypePage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getBusinessTypes(page, limit)
      .then((data) => {
        setBusinessType(data.data.business_type.rows);
        setBusinessTypeTotal(data.data.business_type.totalItems);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    context(["Business Types", "Business Types List"])
    getBusinessTypes(businessTypesPage, businessTypeLimit)
      .then((data) => {
        setBusinessType(data.data.business_type.rows);
        setBusinessTypeTotal(data.data.business_type.totalItems);
      })
      .catch((err) => console.log(err));
  }, [update]);
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Manage Business Types
        </h3>
        <Button
          type="submit"
          className="w-full"
          variant="contained"
          style={{ color: "white" }}
          onClick={() => {
            navigate("/BusinessType/Create", { state: { id: null } });
          }}
        >
          Add Business Type
        </Button>
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Business Type
              </th>
              <th scope="col" class="py-3 px-6 uppercase text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {businessTypes?.map((type, index) => {
              console.log(type);
              return (
                <tr class="bg-white border-b" key={type?.id}>
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    key={type?.id}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{type?.type}</td>
                  <td class="py-4 px-6  text-center">
                    <div className="grid grid-cols-2 gap-2">
                      <Box>
                      <IconButton
                        className="w-fit"
                        aria-label="edit"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/BusinessType/Create", {
                            state: {
                              id: type?.id,
                              type: type?.type,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      </Box>
                      <Box>
                      <IconButton
                        className="w-fit"
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          Swal.fire({
                            title: "Do you want to delete this Business Type?",
                            showCancelButton: true,
                            confirmButtonText: "Delete",
                            confirmButtonColor: "#2EBAA3",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteBusinessType(type?.id)
                                .then((data) => {
                                  Swal.fire("Deleted", "", "success");
                                  setInterval(() => {
                                    setUpdate(!update);
                                  }, 200);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      </Box>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={businessTypeTotal}
        page={businessTypesPage}
        onPageChange={handleChangePage}
        rowsPerPage={businessTypeLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default BusinessTypeList;
