import React, { useEffect, useState } from 'react'
import { DashboardCards, RevenueCards } from '../../components';
import { clientInfoDashboardHeaders, vendorInfoDashboardHeaders } from './utils';
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { getUserInfo } from './Helper';
import {ArrowBack} from "@mui/icons-material";
import VendorUserList from '../UserManager/Vendor/VendorUserList';
import { useQueryParams } from '../../hooks';

function UserInfoDashboard() {
  const {userid} = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const vendorRoute = location.pathname.includes("Vendor");
  const { getParam } = useQueryParams();
  
  
  const [userDashboardData, setUserDashboardData] = useState({});
  const navigate = useNavigate();
  const context = useOutletContext();

  useEffect(() => {
    context(["User Manager", `${vendorRoute ? ("Vendor", "Vendor Info") : ("Client" , "Client Info")}`]);
    getUserInfo(userid, vendorRoute).then((res)=> {
      console.log(res.data);
      setUserDashboardData(res.data);
    })
  },[userid])

  const renderUserDashboardCards = (item, index) => {    
    return(
      <DashboardCards {...userDashboardData[item.key]} label={item.label} vendorRoute={vendorRoute} iconData={{icon:item.icon, color: item.color}} index={index} />
    )
  }

  const handlNavigateBacktoVendorList = async () => {
    return navigate(`/UserManager/${vendorRoute ? "Vendor" : "Client"}?page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
  }

  const userName = searchParams.get("user-name") || "";

  return (
    <div className='flex flex-col'>
      <div className="flex py-4 pl-6">
        <button className='pl-2 pr-4 py-2 bg-[#2EBAA3] rounded-md text-[#fff]' onClick={handlNavigateBacktoVendorList}> <ArrowBack sx={{marginRight: "8px"}} /> Go Back</button>
      </div>
      <div className="flex flex-col pl-6 pb-4">
        <h1 className='text-3xl'>{userName} Overview</h1>
      </div>
      <div className="flex flex-wrap justify-start pl-6">
        {(vendorRoute ? vendorInfoDashboardHeaders : clientInfoDashboardHeaders).map(renderUserDashboardCards)}
      </div>
      <VendorUserList isChildUser={true} />
      {/* <div className="flex pl-6 pt-4">
        <RevenueCards {...userDashboardData[revenueHeaders.key]} iconData={{icon: revenueHeaders.icon, color: revenueHeaders.color}} label={revenueHeaders.label} />
      </div> */}
    </div>
  )
}

export default UserInfoDashboard;
