import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { TextField, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { addJobCategory, createBusinessType, updateBusinessType, updateJobCategory } from "./Helper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function JobCategoryUpdate () {
  const location = useLocation();
  const context = useOutletContext();
  const id = location.state?.id == null ? null : location?.state?.id;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: location.state?.type,
    },
  });
  
  useEffect(()=>{
    context(["Job Category", id == null ? "Add Job Category" : "Update Job Category"])
  },[])

  const handleNavigateJobCategoryPage = () => {
    navigate("/JobCategory")
  }

  const submit = (formData) => {
    if (id == null) {
      addJobCategory({ category: formData?.category })
        .then((res) => {
          console.log(res.data);
          navigate("/JobCategory");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateJobCategory(id, {category: formData?.category })
        .then((res) => {
          console.log(res.data);
          navigate("/JobCategory");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="flex flex-col justify-between">
        <Button sx={{ display: "flex", alignItems :"center" ,width: "fit-content"}} onClick={handleNavigateJobCategoryPage}>
          <ArrowBackIcon />
          <Typography paddingLeft={"4px"}>Go Back</Typography>
        </Button>
        <h3
          className="col-span-5 text-lg pt-1 pl-3 uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          {id == null ? "Create" : "Update"} Job Category
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <div className="w-80 pt-2 pl-2">
            <TextField
              id="outlined-basic"
              {...register("category", { required: true })}
              label="Job Category *"
              margin="normal"
              variant="outlined"
              size="small"
              fullWidth
            />
            {errors.category && (
              <p className="text-red-500 text-xs">Job Category is required</p>
            )}
          </div>
          <div className="text-right pt-10">
            <Button
              type="submit"
              variant="contained"
              style={{ color: "white" }}
            >
              {id == null ? "Add" : "Update"} Job Category
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobCategoryUpdate;
