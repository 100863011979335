import axios from "axios";
import { refreshAccessToken } from "../pages/Auth/Helper";
import Swal from "sweetalert2";
export const siteURL = process.env.REACT_APP_BASE_URL;
export const baseURL = `${process.env.REACT_APP_BASE_URL}`;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("ProlegionAccessToken")}`,
  },
});

const handleTokenExpiration = async(error) => {
  const originalRequest = error.config;
  
  // Check if the error status is 401 and the original request was not already retried
  if (error.response.status === 401 && !originalRequest._retry) {
    console.log();
    originalRequest._retry = true;
    
    // Fetch the new access token using your refresh token logic
    // This example assumes you have a function called "refreshAccessToken"
    return refreshAccessToken({refreshToken: localStorage.getItem("ProlegionRefreshToken")})
      .then((response) => {
        if (response.status === 200) {
          // Update the access token in the local storage and the request header
          localStorage.setItem("ProlegionAccessToken", response.data.result.access_token);
          localStorage.setItem("ProlegionRefreshToken", response.data.result.refresh_token);
          api.defaults.headers.common['Authorization'] = `Bearer ${response.data.result.access_token}`;
          
          // Retry the original request with the updated access token
          return api(originalRequest);
        }
      })
      .catch((error) => {
        // Handle the refresh token failure (e.g., log out the user, show an error message)
        // You can also redirect the user to the login page or take other appropriate actions
        console.error('Error refreshing access token: ', error);
        Swal.fire("Session Expired", "Please login again", "info");
        localStorage.removeItem('ProlegionAccessToken');
				localStorage.removeItem('ProlegionRefreshToken');
        setTimeout(()=>{
          window.location.reload();
        }, 5000)
        // Do something to handle the error (e.g., log out the user, show an error message)
        // Or redirect the user to the login page
      });
  }
  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      return handleTokenExpiration(error);
    } else {
      // Handle other types of errors (e.g., network errors)
      return Promise.reject(error);
    }
  }
);

api.interceptors.request.use(function (config) {
  if(localStorage.getItem("ProlegionAccessToken") != null){
    console.log('Added headers');
    config.headers.Authorization = `Bearer ${localStorage.getItem("ProlegionAccessToken")}`;
  }
  return config;
});

export default api;