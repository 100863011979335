import api, { baseURL } from "../../../utils/api"
export const createBusinessType = (buisnessType) =>{
	return api.post(`${baseURL}/user/addbusinesstype`, buisnessType);
}

export const updateBusinessType = (buisnessType) =>{
	return api.put(`${baseURL}/user/updatebusinesstype`, buisnessType);
}

export const getBusinessTypes = (page, limit) =>{
	return api.get(`${baseURL}/user/getbusinesstypes?page=${page}&size=${limit}`);
}

export const deleteBusinessType = (id) =>{
	return api.delete(`${baseURL}/user/deletebusinesstype?id=${id}`);
}