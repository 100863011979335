import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const Navbar = (props) => {
  return (
    <div className='h-14 border-t-gray-300 border-b-gray-300 border flex items-center px-4'>
        <div className='grid grid-flow-col p-4'>
          {                                                                                       
            props.path.map((path, index)=>{
              return(
                <div className='col-span-1' style={{maxWidth:'max-content'}}>
                  {index != props.path.length -1 ?
                    <h4 className='text-sm font-medium uppercase' style={{color:'#4B4B4B', display:'inline', maxWidth:'max-content'}}>{path} &nbsp;&nbsp; <ArrowForwardIosIcon fontSize='inherit'/> &nbsp;&nbsp;</h4>
                  :
                    <h4 className='text-sm font-medium uppercase' style={{color:'#2EBAA3', display:'inline'}}>{path}</h4>
                  }
                </div>                                                       
              )
            })
          }
        </div>
    </div>
  )
}

export default Navbar