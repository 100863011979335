import api, { baseURL } from "../../../utils/api"

export const createFAQ = (faq) =>{
	return api.post(`${baseURL}/faq/addfaq`, faq);
}

export const updateFAQ = (faq) =>{
	return api.put(`${baseURL}/faq/updatefaq`, faq);
}

export const updateFAQOrder = (faq) =>{
	return api.put(`${baseURL}/faq/updatefaqorder`, faq);
}

export const getFAQs = (page, limit) =>{
	return api.get(`${baseURL}/faq/getfaqs?page=${page}&size=${limit}`);
}
export const getFAQbySection = (section) =>{
	return api.get(`${baseURL}/faq/getfaqsbysection?section=${section}`);
}

export const deleteFAQ = (id) =>{
	return api.delete(`${baseURL}/faq/deletefaq?id=${id}`);
}