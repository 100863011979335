import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";

const JobMProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [projectsPage, setProjectsPage] = useState(1);
  const [projectsLimit, setProjectsLimit] = useState(9);
  const [projectsTotal, setProjectsTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const updatePagination = (_event, page) => {
    // GetCustomers(page, clientsLimit)
    // 	.then((data) => {
    // 		setClients(data.data.docs);
    // 		setClientsTotal(data.data.totalDocs);
    // 	})
    // 	.catch((err) => console.log(err));
    //   setClientsPage(page);
  };

  const navigate = useNavigate();
  useEffect(() => {}, []);
  const viewClient = (e, id) => {
    e.preventDefault();
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Manage Projects
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          class="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Date Created
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Project Name
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Client/Vendor
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {
                    customers?.map((customer, index) => {
                      return (
							<tr class="bg-white border-b ">
								<th
									scope="row"
									class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
									// onClick={(e) => viewCustomer(e, customer._id)}
									key={index}
								>
									{customer?.name}
								</th>
								<td class="py-4 px-6">
									{customer?.contractPeriod.years > 0
										? customer?.contractPeriod.years
										: `${customer?.contractPeriod.months} Months`}
								</td>
								<td class="py-4 px-6">{customer?.contactNumber}</td>
								<td class="py-4 px-6">{customer?.chargers}</td>
								<td class="py-4 px-6">{customer?.sites}</td>
							</tr>
						);
                    })
                  } */}
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-2 p-4">
        <h3 className="text-base">
          <b>
            {(projectsPage - 1) * projectsLimit > 0
              ? (projectsPage - 1) * projectsLimit
              : 0}
          </b>
          &nbsp;-&nbsp;
          <b>
            {projectsPage * projectsLimit > projectsTotal
              ? projectsTotal
              : projectsPage * projectsLimit}
          </b>{" "}
          of {projectsTotal} Items
        </h3>
        <div className="text-right">
          <div className="inline-flex mt-2 xs:mt-0">
            <Stack spacing={2}>
              <Pagination
                onChange={updatePagination}
                siblingCount={4}
                count={totalPages}
                renderItem={(item) => <PaginationItem {...item} />}
              />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobMProjectList;
