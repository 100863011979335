import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { clientVendorConnectionHeaders, getClientVendorList } from "./Helper";
import { TablePagination } from "@mui/material";
import {ArrowBack as ArrowBackIcon} from "@mui/icons-material";
import Swal from "sweetalert2";
import { useQueryParams } from "../../../hooks";


function ClientVendorConnection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [vendorConnections, setVendorConnections] = useState([]);
  const [vendorPage, setVendorPage] = useState(0);
  const [vendorLimit, setVendorLimit] = useState(9);
  const [vendorTotal, setVendorTotal] = useState(0);
  const context = useOutletContext();
  const { vendorid } = useParams();
  const navigate = useNavigate();
  const { getParam } = useQueryParams();

  const connectionTitle = searchParams.get('connectiontitle')

  const fetchConnectedVendors = async (vendorid) => {
    try {
      const res = await getClientVendorList(vendorid);
      console.log(res?.data?.partner_details?.rows);
      setVendorTotal(res?.data?.partner_details?.rows?.length)
      if(res?.data?.partner_details?.rows?.length === 0) {
        navigate(`/UserManager/Client?page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
        Swal.fire("Info", "No Vendors Found" , "info")
      }
      setVendorConnections(res?.data?.partner_details?.rows);
    } catch (error) {
      if(!error?.response?.data?.success) {
        navigate(`/UserManager/Client?page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
        Swal.fire("Info", error?.response?.data?.msg , "info")
      }
    }
  }

  useEffect(() => {
    context(["User Manager", "Client", "Client Vendor Connections"]);
    fetchConnectedVendors(vendorid, connectionTitle);
  }, [vendorid, connectionTitle])

  const rendervendorConnectionsHeaders = (item, index) => (
    <th scope="col" class="py-3 px-6 uppercase" key={index}>{item}</th>
  )

  const handleChangePage = (event, newPage) => {
    // getVendorClientList(vendorid, connectionTitle).then((res) => {
    //   setVendorConnections(res?.data?.partner_details?.rows);
    // })
    setVendorPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setVendorLimit(parseInt(event.target.value, 10));
    setVendorPage(0);
    // getVendorClientList(vendorid, connectionTitle).then((res) => {
    //   setVendorConnections(res?.data?.partner_details?.rows);
    // })
  };

  return (
    <div className="p-5 shadow-md bg-white">
      <div className="flex flex-col items-start">
        <button className="flex rounded-lg p-2 mb-4 bg-[#2EBAA3] text-[#fff]" onClick={()=> navigate('/UserManager/Client')}>
          <ArrowBackIcon sx={{marginRight: "8px"}} />
          Go Back
        </button>
        <h1 className="col-span-5 text-lg uppercase tracking-wider font-bold pb-4" style={{ color: "#2EBAA3" }}>Vendors List</h1>
      </div>
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>{clientVendorConnectionHeaders.map(rendervendorConnectionsHeaders)}</tr>
        </thead>
        <tbody>
          {vendorConnections?.map((vend, index) => {
            return (
              <tr class="bg-white border-b ">
                <th
                  scope="row"
                  class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                  // onClick={(e) => viewCustomer(e, customer._id)}
                  key={index}
                >
                  {index + 1}
                </th>
                <td class="py-4 px-6">
                  <a href={`${process.env.REACT_APP_BASE_FE_URL}/${vend?.vendor?.username}`} target="_blank">{vend?.vendor?.company}</a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination
        component="div"
        count={vendorTotal}
        page={vendorPage}
        onPageChange={handleChangePage}
        rowsPerPage={vendorLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  )
}

export default ClientVendorConnection;