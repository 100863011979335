import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@mui/material";
import { getFAQbySection, updateFAQOrder } from "./Helper";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";

const DragHandle = sortableHandle(() => (
  <DragHandleIcon className="cursor-row-resize" />
));

const SortableItem = sortableElement(({ value }) => (
  <ListItem disablePadding>
    <ListItemIcon>
      <DragHandle />
    </ListItemIcon>
    <Accordion className="w-full my-1" style={{borderRadius: '0px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="rounded-xl"
      >
        <h1 className="text-base font-semibold">{value?.title}</h1>
      </AccordionSummary>
      <AccordionDetails>
        <h1 className="text-base">
            {value?.description}
        </h1>
      </AccordionDetails>
    </Accordion>
  </ListItem>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <List>{children}</List>;
});

const FAQSort = () => {
  const context = useOutletContext();
  const [selectedSection, setSelectedSection] = useState("General");
  const [faqs, setFAQs] = useState([]);

  useEffect(() => {
    getFAQbySection(selectedSection).then((res)=>{
        setFAQs(res.data?.faq);
    }).catch((err)=>{
        console.log(err);
    })
  }, [selectedSection]);

  useEffect(() => {
    context(["FAQ", "Sort FAQs"]);
    getFAQbySection(selectedSection).then((res)=>{
        setFAQs(res.data?.faq);
    }).catch((err)=>{
        console.log(err);
    })
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFAQs(arrayMoveImmutable(faqs, oldIndex, newIndex));
  };

  const submit = () => {
    console.log(faqs);
    let sorted_faqs = [];
    for(let i=0;i<faqs.length; i++){
        sorted_faqs.push({
            id: faqs[i].id,
            title: faqs[i].title,
            faq_no: i+1
        })
    }
    console.log(sorted_faqs);
    updateFAQOrder({faqs: sorted_faqs}).then((res)=>{
        Swal.fire("", "FAQs Order updated successfully", "success")
    }).catch((err)=>{
        console.log(err);
        Swal.fire("Oops!", "Something went wrong", "error")
    })
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Sort FAQs
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <br />
        <FormControl size="small" className="w-1/3">
          <InputLabel id="demo-simple-select-label">Section</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={"General"}
            label="Section"
            onChange={(e) => setSelectedSection(e.target.value)}
          >
            <MenuItem value={"General"}>General</MenuItem>
            <MenuItem value={"Client"}>Client</MenuItem>
            <MenuItem value={"Vendor"}>Vendor</MenuItem>
            <MenuItem value={"Consultant"}>Consultant</MenuItem>
          </Select>
        </FormControl>
        <br />
        <div className="p-5 overflow-y-scroll" style={{ height: "50vh" }}>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {faqs.map((value, index) => (
              <SortableItem key={`item-${value?.id}`} index={index} value={value} />
            ))}
          </SortableContainer>
        </div>
      </div>
      <div className="text-right pt-10">
        <Button onClick={e=>submit()} variant="contained" style={{ color: "white" }}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default FAQSort;
