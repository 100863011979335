import api, { baseURL } from "../../../../utils/api"

export const getAllVendors = (page, limit, searchQuery) =>{
	return api.get(`${baseURL}/user/allvendors?search=${searchQuery ? searchQuery : ""}&page=${page}&size=${limit}`);
}

export const getVendorChildUsers = (page, limit, searchQuery, userId) =>{
	return api.get(`${baseURL}/user/fetch/childusers?search=${searchQuery ? searchQuery : ""}&page=${page}&size=${limit}&user_id=${userId}`);
}

export const getVendorClientList = (id, userType) => {
	return api.get(`${baseURL}/user/allvendors/${id}/${userType}`);
}

export const deleteVendor = (vendorId) =>{
	return api.delete(`${baseURL}/user/deleteuser?id=${vendorId}`);
}

export const vendorListHeaders = [
	"Sr.",
  "Date Created",
  "Name",
  "Username",
  // "ClientList",
  // "VendorList",
  // "ResourceList",
  "Email ID",
  "Mobile Number",
  "Company",
  "Status",
  "Self Managed Resources"
]

export const VendorClientConnectionsHeaders = [
  "Sr.",
  "Name",
]