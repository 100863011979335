import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { createFAQ, updateFAQ } from "./Helper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const FAQUpdate = () => {
  const context = useOutletContext();
  const location = useLocation();
  const id = location.state?.id == null ? null : location?.state?.id;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      section: location.state?.section_name,
      title: location.state?.title,
      description: location.state?.description,
    },
  });

  useEffect(()=>{
    context(["FAQ", id == null ? "Add FAQ" : "Update FAQ"])
  },[])

  const submit = (formData) => {
    if (id == null) {
      createFAQ({
        title: formData?.title,
        description: formData?.description,
        section_name: formData?.section,
      })
        .then((data) => {
          console.log(data.data);
          navigate("/FAQ");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateFAQ({
        id: id,
        title: formData?.title,
        description: formData?.description,
        section_name: formData?.section,
      })
        .then((data) => {
          console.log(data.data);
          navigate("/FAQ");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          {id == null ? "Create" : "Update"} FAQ
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <div className="pt-2">
            <FormControl className="w-80" size="small">
              <InputLabel size="small" id="demo-simple-select-label">
                Section *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={location.state?.section_name}
                label="Section *"
                size="small"
                {...register("section", { required: true })}
              >
                <MenuItem value={"General"}>General</MenuItem>
                <MenuItem value={"Client"}>Client</MenuItem>
                <MenuItem value={"Vendor"}>Vendor</MenuItem>
                <MenuItem value={"Consultant"}>Consultant</MenuItem>
              </Select>
            </FormControl>
            {errors.section && (
              <p className="text-red-500 text-xs">Section is required</p>
            )}
            <br />
            <TextField
              className="w-1/2"
              minRows={2}
              multiline
              id="outlined-basic"
              {...register("title", { required: true })}
              label="Question *"
              margin="normal"
              variant="outlined"
              size="small"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">Question is required</p>
            )}
          </div>
          <TextField
            id="outlined-basic"
            {...register("description", { required: true })}
            multiline
            minRows={10}
            label="Answer *"
            margin="normal"
            variant="outlined"
            size="small"
            fullWidth
          />
          {errors.name && (
            <p className="text-red-500 text-xs">Answer is required</p>
          )}
          <br />
          <div className="text-right pt-10">
            <Button
              type="submit"
              variant="contained"
              style={{ color: "white" }}
            >
              {id == null ? "Add" : "Update"} FAQ
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FAQUpdate;
