import React from "react";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import "../App.css";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useState } from "react";
import { useEffect } from "react";
const Holder = () => {
    const [path, setPath] = useState([]);
    useEffect(()=>{
        console.log("Path Updated: "+path);
    }, [path])
  return (
    <div>
      <div class="h-screen flex">
        <div class="bg-gray-600 w-64 no-scrollbar">
          <Sidebar />
        </div>
        <div class="flex-1 flex overflow-hidden">
          <div class="flex-1 overflow-y-scroll">
            <header class="h-14">
              <Header />
            </header>
            <div>
              <Navbar path={path} />
              <main class="mb-auto">
                <div className="border-gray-300 p-3 min-h-screen">
                  <Outlet context={setPath}/>
                </div>
              </main>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Holder;
