import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TablePagination } from "@mui/material";
import { deleteSkill, getAllSkills } from "./Helper";
import Swal from "sweetalert2";

const JobMSkillList = () => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [skillsPage, setSkillsPage] = useState(0);
  const [skillsLimit, setSkillsLimit] = useState(10);
  const [skillsTotal, setSkillsTotal] = useState(0);
  const [update, setUpdate] = useState(false);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, skillsLimit);
    setSkillsPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSkillsLimit(parseInt(event.target.value, 10));
    setSkillsPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getAllSkills(page, limit)
      .then((data) => {
        setSkills(data.data.skill.rows);
        setSkillsTotal(data.data.skill.totalItems);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    context(["Job Manager", "Skill"]);
    getAllSkills(skillsPage, skillsLimit)
      .then((data) => {
        console.log(data.data);
        setSkills(data.data.skill.rows);
        setSkillsTotal(data.data.skill.totalItems);
      })
      .catch((err) => console.log(err));
  }, [update]);
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Manage Skills
        </h3>
        <Button
          type="submit"
          className="w-full"
          variant="contained"
          style={{ color: "white" }}
          onClick={() => {
            navigate("/JobManager/Skill/Create", { state: { id: null } });
          }}
        >
          Add Skill
        </Button>
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Skill
              </th>
              <th scope="col" class="py-3 px-6 uppercase text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {skills?.map((skill, index) => {
              return (
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    // onClick={(e) => viewCustomer(e, customer._id)}
                    key={index}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{skill?.name}</td>
                  <td class="py-4 px-6  text-center">
                    <div className="grid grid-cols-2 gap-2">
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/JobManager/Skill/Create", {
                            state: {
                              id: skill?.id,
                              name: skill?.name,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          Swal.fire({
                            title: "Do you want to delete this Business Type?",
                            showCancelButton: true,
                            confirmButtonText: "Delete",
                            confirmButtonColor: "#2EBAA3",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteSkill(skill?.id)
                                .then((data) => {
                                  Swal.fire("Deleted", "", "success");
                                  setInterval(() => {
                                    setUpdate(!update);
                                  }, 200);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={skillsTotal}
        page={skillsPage}
        onPageChange={handleChangePage}
        rowsPerPage={skillsLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default JobMSkillList;
