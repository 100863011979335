import { BrowserRouter, Route, Routes } from "react-router-dom";
import { isLoggedIn } from "./pages/Auth/Helper";
import Login from "./pages/Auth/Login";
import Holder from "./pages/Holder";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PendingApproval from "./pages/PendingApproval/PendingApproval";
import PendingClient from "./pages/PendingApproval/Client/PendingClient";
import PendingClientList from "./pages/PendingApproval/Client/PendingClientList";
import PendingVendor from "./pages/PendingApproval/Vendor/PendingVendor";
import PendingVendorList from "./pages/PendingApproval/Vendor/PendingVendorList";
import PendingJob from "./pages/PendingApproval/Job/PendingJob";
import PendingJobList from "./pages/PendingApproval/Job/PendingJobList";
import PendingProject from "./pages/PendingApproval/Project/PendingProject";
import PendingProjectList from "./pages/PendingApproval/Project/PendingProjectList";
import ClientUser from "./pages/UserManager/Client/ClientUser";
import ClientUserList from "./pages/UserManager/Client/ClientUserList";
import ConsultantUserList from "./pages/UserManager/Consultant/ConsultantUserList";
import ConsultantUser from "./pages/UserManager/Consultant/ConsultantUser";
import VendorUserList from "./pages/UserManager/Vendor/VendorUserList";
import UserManager from "./pages/UserManager/UserManager";
import VendorUser from "./pages/UserManager/Vendor/VendorUser";
import JobManager from "./pages/JobManager/JobManager";
import JobMJob from "./pages/JobManager/Job/JobMJob";
import JobMJobList from "./pages/JobManager/Job/JobMJobList";
import JobMJobSub from "./pages/JobManager/JobSub/JobMJobSub";
import JobMSubList from "./pages/JobManager/JobSub/JobMSubList";
import JobMProject from "./pages/JobManager/Project/JobMProject";
import JobMProjectList from "./pages/JobManager/Project/JobMProjectList";
import JobMSkillList from "./pages/JobManager/Skill/JobMSkillList";
import JobMSkill from "./pages/JobManager/Skill/JobMSkill";
import JobMSkillUpdate from "./pages/JobManager/Skill/JobMSkillUpdate";
import Dashboard from "./pages/Dashboard/Dashboard";
import EmailTemp from "./pages/EmailTemp/EmailTemp";
import EmailTemplates from "./pages/EmailTemp/Templates/EmailTemplates";
import EmailTempList from "./pages/EmailTemp/Templates/EmailTemplateList";
import EmailTempUpdate from "./pages/EmailTemp/Templates/EmailTemplateUpdate";
import EmailTemplateDesign from "./pages/EmailTemp/Templates/EmailTemplateDesign";
import FAQ from "./pages/FAQ/FAQ";
import FAQList from "./pages/FAQ/FAQList";
import FAQUpdate from "./pages/FAQ/FAQUpdate";
import FAQSort from "./pages/FAQ/FAQSort";
import BusinessType from "./pages/BuisnessType/BusinessType";
import BusinessTypeList from "./pages/BuisnessType/BusinessTypeList";
import BusinessTypeUpdate from "./pages/BuisnessType/BusinessTypeUpdate";
import JobCategory from "./pages/JobCategory/JobCategory";
import JobCategoryTypeList from "./pages/JobCategory/JobCategoryTypeList";
import JobCategoryUpdate from "./pages/JobCategory/JobCategoryUpdate";
import { ClientVendorConnection, UserInfoDashboard, VendorClientResourcesConnectionList } from "./pages";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EBAA3", 
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="Login" element={<Login />} />
          <Route
            path="/"
            element={isLoggedIn() === true ? <Holder /> : <Login />}
          >
            <Route index element={<Dashboard />}/>
            <Route path="/Dashboard" element={<Dashboard />}/>
            <Route path="/PendingApproval" element={<PendingApproval />}>
              <Route path="ClientApproval" element={<PendingClient/>}>
                <Route path="" element={<PendingClientList />}/>
              </Route>
              <Route path="VendorApproval" element={<PendingVendor/>}>                                                                         
                <Route path="" element={<PendingVendorList />}/>
              </Route>
              <Route path="JobApproval" element={<PendingJob />}>
                <Route path="" element={<PendingJobList />}/>
              </Route>
              <Route path="ProjectApproval" element={<PendingProject />}>
                <Route path="" element={<PendingProjectList />}/>
              </Route>
            </Route>

            <Route path="/EmailTemplates" element={<EmailTemp />}>
              <Route path="Templates" element={<EmailTemplates/>}>
                <Route path="" element={<EmailTempList />}/>
                <Route path="Create" element={<EmailTempUpdate />}/>
                <Route path="Design" element={<EmailTemplateDesign />}/>
              </Route>
            </Route>

            <Route path="/FAQ" element={<FAQ />}>
              <Route path="" element={<FAQList />}/>
              <Route path="Create" element={<FAQUpdate />}/>
              <Route path="Sort" element={<FAQSort />}/>
            </Route>

            <Route path="/BusinessType" element={<BusinessType />}>
              <Route path="" element={<BusinessTypeList />}/>
              <Route path="Create" element={<BusinessTypeUpdate />}/>
            </Route>
          
            <Route path="/JobCategory" element={<JobCategory />}>
              <Route path="" element={<JobCategoryTypeList />}/>
              <Route path="Create" element={<JobCategoryUpdate />} />
            </Route>

            <Route path="/UserManager" element={<UserManager />}>
              <Route path="Client" element={<ClientUser />}>
              <Route path="" element={<ClientUserList />}/>
              <Route path="client-info/:userid" element={<UserInfoDashboard />} />
              <Route path="vendor-list/:vendorid" element={<ClientVendorConnection />}/>
              </Route>                                      
              <Route path="Consultant" element={<ConsultantUser />}>
              <Route path="" element={<ConsultantUserList />}/>
              </Route>
              <Route path="Vendor" element={<VendorUser />}>
              <Route path="" element={<VendorUserList />}/>
              <Route path="vendor-info/:userid" element={<UserInfoDashboard />} />
              <Route path="cvr-list/:vendorid" element={<VendorClientResourcesConnectionList />}/>
              </Route>
            </Route>
            <Route path="/JobManager" element={<JobManager />}>
              <Route path="Job" element={<JobMJobSub />}>
                <Route path="" element={<JobMJobList />}/>
              </Route>
              <Route path="JobSub" element={<JobMJobSub />}>
                <Route path="" element={<JobMSubList />}/>
              </Route>
              <Route path="Project" element={<JobMProject />}>
                <Route path="" element={<JobMProjectList />}/>
              </Route>
              <Route path="Skill" element={<JobMSkill />}>
                <Route path="" element={<JobMSkillList />}/>
                <Route path="Create" element={<JobMSkillUpdate />}/>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
