import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { deleteJobCategory, getJobCategoryList } from "./Helper";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { TablePagination } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

function JobCategoryTypeList() {
  const navigate = useNavigate();
  const context = useOutletContext();
  const [jobCategoryList, setJobCategoryList] = useState([]);
  const [jobCategroyPage, setJobCategroyPage] = useState(0);
  const [jobCategoryPageLimit, setJobCategoryPageLimit] = useState(10);
  const [jobCategoryTypeTotal, setJobCategoryTypeTotal] = useState(0);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, jobCategoryPageLimit);
    setJobCategroyPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setJobCategoryPageLimit(parseInt(event.target.value, 10));
    setJobCategroyPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    setIsLoading(true);
    getJobCategoryList(page, limit)
      .then((data) => {
      setIsLoading(false);
        setJobCategoryList(data.data.rows);
        setJobCategoryTypeTotal(data.data.totalItems);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    context(["Job Category", "Job Category List"])
    setIsLoading(true);
    getJobCategoryList(jobCategroyPage, jobCategoryPageLimit)
      .then((data) => {
        setIsLoading(false);
        setJobCategoryList(data.data.rows);
        setJobCategoryTypeTotal(data.data.totalItems);
      })
      .catch((err) => console.log(err));
  }, [update]);

  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Manage Job Categories
        </h3>
        <Button
          type="submit"
          className="w-full"
          variant="contained"
          style={{ color: "white" }}
          onClick={() => {
            navigate("/JobCategory/Create", { state: { id: null } });
          }}
        >
          Add Job Category
        </Button>
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Job Category
              </th>
              <th scope="col" class="py-3 px-6 uppercase text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? <CircularProgress/> : jobCategoryList?.map((type, index) => {
              console.log(type);
              return (
                <tr class="bg-white border-b" key={type?.id}>
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    key={type?.id}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{type?.category}</td>
                  <td class="py-4 px-6  text-center">
                    <div className="grid grid-cols-2 gap-2">
                      <Box>
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/JobCategory/Create", {
                              state: {
                                id: type?.id,
                                type: type?.category,
                              },
                            });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          aria-label="delete"
                          disabled={index === 0 && type?.id === `c4aad4a6-05b7-45d7-91f6-5e8f0c67ad9c` || type?.id === `1625923d-044d-488d-a7af-713b982a5c17` || type?.id === `fdab8fe9-7769-46a6-82d5-2edafda5d0a0`}
                          onClick={(e) => {
                            e.preventDefault();
                            Swal.fire({
                              title: "Do you want to delete this Job Category Type?",
                              showCancelButton: true,
                              confirmButtonText: "Delete",
                              confirmButtonColor: "#ff0000",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteJobCategory(type?.id)
                                  .then((data) => {
                                    Swal.fire("Deleted", "", "success");
                                    setInterval(() => {
                                      setUpdate(!update);
                                    }, 200);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={jobCategoryTypeTotal}
        page={jobCategroyPage}
        onPageChange={handleChangePage}
        rowsPerPage={jobCategoryPageLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default JobCategoryTypeList;
