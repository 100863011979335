import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../hooks';

function DashboardCards(props) {
  const {label, iconData, current, total, Open, Ongoing, closed, rejected, paid, unpaid, draft, requested = 0, approved, pending, vendorRoute, index} = props;

  const isRedirect = ["Clients", "Partners", "Vendors", "Resources"].includes(label) 
  
  const navigate = useNavigate();
  const param = useParams();
  const {getParam} = useQueryParams();
  const {pathname} = useLocation();

  const isVendor = pathname.includes("Vendor");

  const handleNavigateUser = () => {
    if(isRedirect) {
      if(isVendor) {
        console.log("vendor route");
        label === "Clients" && navigate(`/UserManager/Vendor/cvr-list/${param.userid}?connectiontitle=CLIENT&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
        label === "Partners" && navigate(`/UserManager/Vendor/cvr-list/${param.userid}?connectiontitle=VENDOR&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
        label === "Resources" && navigate(`/UserManager/Vendor/cvr-list/${param.userid}?connectiontitle=RESOURCE&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);  
      } else {
        label === "Clients" && navigate(`/UserManager/Client/vendor-list/${param.userid}?connectiontitle=CLIENT&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
        label === "Vendors" && navigate(`/UserManager/Client/vendor-list/${param.userid}?connectiontitle=VENDOR&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
        label === "Resources" && navigate(`/UserManager/Client/vendor-list/${param.userid}?connectiontitle=RESOURCE&page=${getParam("page")}&pageSize=${getParam("pageSize")}`);
      }
    }
  }

  
  return (
    <div key={index} className={`flex flex-col p-4 m-1 bg-[rgb(255,255,255)] shadow-md rounded-lg w-[32%] ${isRedirect && "hover:bg-slate-50 cursor-pointer"}`} onClick={handleNavigateUser}>
      <div className="flex justify-between pb-4">
        <span className='text-xl font-bold'>{label}</span>
        <div className={`bg-[${iconData?.color}]`}>{iconData.icon}</div>
      </div>
      {current && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Current: {current}</span>
          <span>Requested: {requested}</span>  
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{current + requested}</span>
        </div>
      </div>}
      {approved && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Approved: {approved}</span>
          <div className="flex">
            <span>Pending: {pending}</span>  
            <span className='pl-4'>Rejected: {rejected}</span>
          </div>
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{approved + pending + rejected}</span>
        </div>
      </div>}
      {total && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Total: {total}</span> 
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{total}</span>
        </div>
      </div>}
      {Open && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Open: {Open}</span>
          <div className="flex">
            {Ongoing && <span className='pr-4'>Ongoing: {Ongoing}</span>} 
            <span>Closed: {closed}</span>
          </div>  
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{Open + Ongoing + closed}</span>
        </div>
      </div>}
      {paid && <div className="flex justify-between">
        <div className="flex flex-col">
          <span>Paid: {paid}</span>
          <div className="flex">
            <span className='pr-4'>Ongoing: {unpaid}</span>
            {vendorRoute && <span>Draft: {draft}</span>}
          </div>
        </div>
        <div className="flex flex-col justify-end">
          <span className='text-4xl font-bold'>{paid + unpaid + (vendorRoute ? draft : 0)}</span>
        </div>
      </div>}
    </div>
  )
}

export default DashboardCards;
