import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate, useOutletContext } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { approveProject, deleteProject, getNewProjects } from "./Helper";
import { Button, TablePagination } from "@mui/material";
import { useQueryParams } from "../../../hooks";

const PendingProjectList = () => {
  const context = useOutletContext();
  const [project, setProject] = useState([]);
  const { getParam, setMultiParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [projectPage, setProjectPage] = useState(parseInt(currentPage) || 0);
  const [projectLimit, setProjectLimit] = useState(parseInt(currentLimit) || 9);
  const [projectTotal, setProjectTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [toggle, setToggle] = useState(true);
  const handleChangePage = (event, newPage) => {
    fetchData(newPage, projectLimit);
    setProjectPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setProjectLimit(parseInt(event.target.value, 10));
    setProjectPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getNewProjects(page, limit)
      .then((data) => {
        setProject(data.data.result.rows);
        setProjectTotal(data.data.result.totalItems);
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();
  useEffect(() => {
    context(["Pending Approvals", "Project Approvals"]);
    getNewProjects(projectPage, projectLimit)
      .then((data) => {
        console.log(data.data.result.rows);
        setProject(data.data.result.rows);
        setProjectTotal(data.data.result.totalItems);
      })
      .catch((err) => console.log(err));
  }, [toggle]);

  useEffect(() => {
    setMultiParam({"page": projectPage, "pageSize": projectLimit});
  }, [projectPage, projectLimit]);

  const viewClient = (e, id) => {
    e.preventDefault();
  };
  const approve = (e, id) => {
    e.preventDefault();
    approveProject(id)
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const decline = (e, id) => {
    e.preventDefault();
    deleteProject(id)
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {}, [toggle]);
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Project Approvals
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          class="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Title
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Description
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {project?.map((pr, index) => {
              return (
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    // onClick={(e) => viewCustomer(e, customer._id)}
                    key={index}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{pr?.title}</td>
                  <td class="py-4 px-6">{pr?.description}</td>
                  <td class="py-4 px-6">
                    <div className="grid grid-cols-2 gap-2">
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={(e) => approve(e, pr?.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        size="small"
                        style={{ color: "red", borderColor: "red" }}
                        variant="outlined"
                        onClick={(e) => decline(e, pr?.id)}
                      >
                        Decline
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PendingProjectList;
