import Swal from "sweetalert2";
import api,{baseURL} from "../../../utils/api"
export const isLoggedIn = () => {
	if (localStorage.getItem('ProlegionAccessToken') !== null) {
		return true;
	} else {                                                    
		return false; 
	}
};

export const isAuthenticated = () =>{
	return JSON.parse(localStorage.getItem('ProlegionAccessToken'));
}

export const logout = async() => {
	if (localStorage.getItem('ProlegionAccessToken') !== null && localStorage.getItem('ProlegionRefreshToken') !== null) {
		api.post(`${baseURL}/auth/signout`, {refreshToken: localStorage.getItem('ProlegionRefreshToken')}).then((res)=>{
			console.log(res);
			if(res.data.success){
				localStorage.removeItem('ProlegionAccessToken');
				localStorage.removeItem('ProlegionRefreshToken');
				Swal.fire("", "Successfully logged out", "success");
			}
		}).catch((err)=>{
			console.log(err);
			localStorage.removeItem('ProlegionAccessToken');
			localStorage.removeItem('ProlegionRefreshToken');
		})
		
	}
};

export const login = (creds) =>{
	return api.post(`${baseURL}/auth/adminsignin`, creds);
}

export const refreshAccessToken = (body)=>{
	return api.post(`${baseURL}/auth/refreshtoken`, body);
}