import api, { baseURL } from "../../../../utils/api"

export const getAllClients = (page, limit, searchQuery) =>{
	return api.get(`${baseURL}/user/allclients?search=${searchQuery ? searchQuery : ""}&page=${page}&size=${limit}`);
}

export const deleteClient = (clientId) =>{
	return api.delete(`${baseURL}/user/deleteuser?id=${clientId}`);
}

export const getClientVendorList = (id) => {
	return api.get(`${baseURL}/user/allclients/${id}`);
}


export const clientVendorConnectionHeaders = [
	"Sr No.",
	"Vendor"
]