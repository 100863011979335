import api, { baseURL } from "../../../../utils/api"

export const getAllSkills = (page, limit) =>{
	return api.get(`${baseURL}/jobnproject/getallcmsskills?page=${page}&size=${limit}`);
}

export const addSkill = (skill) =>{
	return api.post(`${baseURL}/jobnproject/addskill`, skill);
}

export const getSkillByID = (id) =>{
	return api.get(`${baseURL}/jobnproject/getskill?id=${id}`);
}

export const deleteSkill = (id) =>{
	return api.delete(`${baseURL}/jobnproject/deleteskill?id=${id}`);
}

export const updateSkill = (skill) =>{
	return api.put(`${baseURL}/jobnproject/updateskill`, skill);
}