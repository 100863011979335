import { Dialog, DialogContent, IconButton, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiModal-backdrop': {
    background: 'rgba(0, 59, 84, 0.5)'
  }
}));


const MuiModal = ({
  open,
  onCancel,
  zIndex = 9999,
  styling = {},
  children,
  maxWith = 'sm',
  title = ''
}) => {

  return (
    <BootstrapDialog
      open={open}
      onClose={onCancel}
      zIndex={zIndex}
      maxWidth={maxWith}
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers style={styling}>
        <Typography variant="subtitle1Bold" color="#182743" component="h2" sx={{ mb: 2.5, fontWeight: "bolder", fontSize: "22px" }}>{title}</Typography>
        {children}
      </DialogContent>
    </BootstrapDialog>
  );
}

export default MuiModal;
