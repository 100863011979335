import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@mui/material";
import { deleteEmailTemplate, getEmailTemplates } from "./Helper";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { TablePagination } from "@mui/material";

const EmailTemplateList = () => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [templatePage, setTemplatePage] = useState(0);
  const [templateLimit, setTemplateLimit] = useState(10);
  const [templateTotal, setTemplateTotal] = useState(0);
  const [update, setUpdate] = useState(false);

  const handleChangePage = (event, newPage) => {
    fetchData(newPage, templateLimit);
    setTemplatePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setTemplateLimit(parseInt(event.target.value, 10));
    setTemplatePage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getEmailTemplates(page, limit)
      .then((data) => {
        setTemplates(data.data.emailTemplates.rows);
        setTemplateTotal(data.data.emailTemplates.totalItems);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    context(["Email Templates", "Email Template List"]);
    getEmailTemplates(templatePage, templateLimit)
      .then((data) => {
        setTemplates(data.data.emailTemplates.rows);
        setTemplateTotal(data.data.emailTemplates.totalItems);
      })
      .catch((err) => console.log(err));
  }, [update]);
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Manage Email Templates
        </h3>
        <Button
          type="submit"
          className="w-full"
          variant="contained"
          style={{ color: "white" }}
          onClick={() => {
            navigate("/EmailTemplates/Templates/Create", {
              state: { id: null },
            });
          }}
        >
          Add Email Template
        </Button>
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Name
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Subject
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Description
              </th>
              <th scope="col" class="py-3 px-6 uppercase text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {templates?.map((template, index) => {
              console.log(template);
              return (
                <tr class="bg-white border-b" key={template?.id}>
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    key={template?.id}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">{template?.name}</td>
                  <td class="py-4 px-6">{template?.subject}</td>
                  <td class="py-4 px-6">{template?.description}</td>
                  <td class="py-4 px-6  text-center">
                    <div className="grid grid-cols-1 gap-2">
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/EmailTemplates/Templates/Create", {
                            state: {
                              id: template?.id,
                              name: template?.name,
                              description: template?.description,
                              content: template?.content,
                              json_content: template?.json_content,
                              subject: template?.subject,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      {/* <IconButton aria-label="delete" onClick={(e)=>{
                            e.preventDefault();
                            Swal.fire({
                              title: 'Do you want to delete this template?',
                              showCancelButton: true,
                              confirmButtonText: 'Delete',
                              confirmButtonColor: '#2EBAA3'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteEmailTemplate(template?.id).then((data)=>{
                                  Swal.fire('Deleted', '', 'success')
                                  setInterval(()=>{
                                    setUpdate(!update);
                                  },200);
                                }).catch((err)=>{
                                  console.log(err);
                                })
                              }
                            })
                          }}>
                            <DeleteIcon />
                          </IconButton> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={templateTotal}
        page={templatePage}
        onPageChange={handleChangePage}
        rowsPerPage={templateLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default EmailTemplateList;
