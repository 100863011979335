import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useForm } from "react-hook-form";
import { login } from "./Helper";
import api from '../../utils/api.js'

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [toggle]);
  const handleLogin = (credentials) => {
    setLoading(true);
    login(credentials).then((data) => {
    setLoading(false);
      if(data?.data?.success){
          api.defaults.headers['Authorization'] = `Bearer ${data.data.result.access_token}`;
          localStorage.setItem("ProlegionAccessToken", data.data.result.access_token);
          localStorage.setItem("ProlegionRefreshToken", data.data.result.refresh_token);
          navigate("/Dashboard")
          window.location.reload();
      }else{
        setLoading(false);
        alert("Invalid Credentials")
      }
    }).catch(err => {
      setLoading(false);
      alert("Invalid Credentials")
      console.log(err)
    })

  };
  return (
    <div className="h-screen" style={{ backgroundColor: "#F5F6FA" }}>
      <div className="p-3">
        <img src={logo} style={{ height: "8vh" }} alt="LOGO" />
        <div className="p-2 grid grid-cols-3 gap-5">
          <div></div>
          <div className="pt-24">
            <h4 className="font-bold text-2xl">Log In</h4>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="pt-4">
                <div>
                  <label
                    htmlFor="username"
                    className="form-label inline-block mb-2 text-gray-700"
                  >
                    Email ID
                  </label>
                  <input
                    type="email"
                    id="username"
                    {...register("username", {
                      required: true,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                    placeholder="Email or Mobile Number"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                  />
                  {errors.username && errors.username.type === "required" && (
                    <p className="errorMsg">Email is required.</p>
                  )}
                  {errors.username && errors.username.type === "pattern" && (
                    <p className="errorMsg">Email is not valid.</p>
                  )}
                </div>
                <br />
                <div>
                  <label
                    htmlFor="password"
                    className="form-label inline-block mb-2 text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    {...register("password", {
                      required: true,
                      pattern:
                        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                      minLength: 8,
                    })}
                    placeholder="Password"
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                  />

                  {errors.password && errors.password.type === "required" && (
                    <p className="errorMsg">Password is required.</p>
                  )}
                  {errors.password && errors.password.type === "pattern" && (
                    <p className="errorMsg">
                      Password must contain At least One Capital Letter, One
                      Small Letter, One Special Character
                    </p>
                  )}
                  {errors.password && errors.password.type === "minLength" && (
                    <p className="errorMsg">
                      Password Should Be At Least 8 Character
                    </p>
                  )}
                </div>
                <br/>
                <Button
                type="submit"
                  className="w-full"
                  variant="contained"
                  style={{ color: "white" }}
                  disabled={loading}
                >
                  {loading ? "loading..." :  "Login"}
                </Button>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
