import api, { baseURL } from "../../../../utils/api"

export const getNewProjects = (page, limit) =>{
	return api.get(`${baseURL}/jobnproject/newprojects?page=${page}&size=${limit}`);
}

export const approveProject = (project) =>{
	return api.put(`${baseURL}/jobnproject/approveproject`, {id: project});
}

export const deleteProject = (projectId) =>{
	return api.delete(`${baseURL}/jobnproject/deleteproject?id=${projectId}`);
}