import api, { baseURL } from "../../../../utils/api"

export const getNewJobs = (page, limit) =>{
	return api.get(`${baseURL}/jobnproject/newjobs?page=${page}&size=${limit}`);
}

export const approveJob = (job) =>{
	return api.put(`${baseURL}/jobnproject/approvejob`, {id: job});
}

export const deleteJob = (jobId) =>{
	return api.delete(`${baseURL}/jobnproject/deletejob?id=${jobId}`);
}